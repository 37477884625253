import React, { useEffect } from 'react';
import './style.css';
import config from '../../_config';
import { GeoService } from '../../_services/geo.service';
import getImageURL from '../../_helpers/getImageURL';
import currencyDisplayHelper from '../../_helpers/currencyDisplayHelper';
import { useNavigate } from 'react-router-dom';

const BookingCard = ({ data, showStatus }) => {
    const mapId = `map-${data._id}`;

    const navigate = useNavigate();

    useEffect(() => {
        if (data && data.directions) {
            let mapElement = document.getElementById(mapId);
            // const centerCoords = GeoService.centerCoordinates(data.directions, data.distance);

            new window.google.maps.Map(mapElement, { center: config.defaultLatLng });

            const map = new window.google.maps.Map(
                mapElement,
                {
                    // center: { lat: centerCoords.latitude, lng: centerCoords.longitude },
                    center: GeoService.findCenter(
                        { latitude: data.pickupAddressLocation.coordinates[0], longitude: data.pickupAddressLocation.coordinates[1] },
                        { latitude: data.destinationAddressLocation.coordinates[0], longitude: data.destinationAddressLocation.coordinates[1] },
                    ),
                    zoom: GeoService.getZoomLevel(data.distance),
                    streetViewControl: false, fullscreenControl: false, zoomControl: false,
                    mapTypeControl: false,
                    styles: [
                        {
                            featureType: "poi.business",
                            stylers: [{ visibility: "off" }],
                        },
                    ],
                }
            );

            const directionsPath = new window.google.maps.Polyline({
                path: data.directions.map(p => { return { lat: p.latitude, lng: p.longitude } }),
                strokeColor: "#326ce1",
                strokeOpacity: 1.0, strokeWeight: 3, geodesic: true,
            });

            directionsPath.setMap(map);

            const marker1 = new window.google.maps.Marker({
                position: {
                    lat: parseFloat(data.pickupAddressLocation.coordinates[0]),
                    lng: parseFloat(data.pickupAddressLocation.coordinates[1])
                },
                title: 'Pickup',
            });
            marker1.setMap(map);

            const marker2 = new window.google.maps.Marker({
                position: {
                    lat: parseFloat(data.destinationAddressLocation.coordinates[0]),
                    lng: parseFloat(data.destinationAddressLocation.coordinates[1])
                },
                title: 'Destination',
            });
            marker2.setMap(map);
        }
    }, [data]);

    return (
        <div className='booking-card'>
            <div id={mapId} className='booking-map'>
            </div>

            <div className='booking-map-inner'>
                <div className='booking-distance center'>
                    {/* {data.categoryObj.name} */}
                    Distance: {data.distance} km
                </div>
            </div>
            <div className='booking-user-container row'>
                <div className='booking-basic-data'>
                    <div className='row center booking-basic-data-inner'>
                        <div className='booking-user-image' style={{ backgroundImage: `url(${getImageURL(data.userObj.profileImage)})` }}>
                        </div>

                        <div className='booking-username'>
                            {data.userObj.name}
                        </div>
                    </div>

                    <div className='booking-category'>{data.categoryObj.name}{showStatus ? ` (${data.status})` : null}</div>
                </div>
                <div className='booking-rate-area column'>
                    <span className='booking-rate'> {currencyDisplayHelper(data.price)}</span>
                    <div className='btn btn-primary btn-sm btn-accept' onClick={() => navigate(`/booking/${data._id}`)}>View</div>
                </div>
            </div>
        </div>
    );
};

export default BookingCard;