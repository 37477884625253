import React, { useEffect, useState } from 'react';
import './style.css';
import { connect } from 'react-redux';
import ParentDropDown from '../../../components/ParentDropDown';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiService } from '../../../_services/api.service';
import BookingCard from '../../../components/BookingCard';

const MarketPlace = ({ infosetting, user, loggedIn }) => {
    const { state, category } = useParams();
    const navigate = useNavigate();

    const [stateValue, setStateValue] = useState(null);
    const [categoryValue, setCategoryValue] = useState(null);
    const [bookings, setBookings] = useState(null);

    useEffect(() => {
        if (infosetting) {
            document.title = `Market Place | ${infosetting.title}`;
        }
    }, [infosetting]);

    useEffect(() => {
        setStateValue(state);
        setCategoryValue(category);
        if (state && category) {
            fetchBookings();
        } else {
            fetchBookings();
        }
    }, [state, category]);

    const fetchBookings = () => {
        apiService.filter("bookings", { query: (state && category) ? { category, state, status: "Requested" } : { state: "66c3a22ac8b64c255ced5f67", status: "Requested" } })
            .then(setBookings);
    }


    const handleSearchClick = () => {
        if (!stateValue || !categoryValue) {
            return toast.error("Please select state and category!")
        }
        navigate(`/marketplace/${stateValue}/${categoryValue}`);
    }


    return (
        <div className='height100'>
            <div className='marketplace-banner center column'>
                <h1 className='mp-banner-title'>Market Place</h1>
                <p className='text-white text-center'>Explore bookings by state and category for a tailored experience.</p>

                <div className='mp-search-area row mt-30'>
                    <ParentDropDown
                        attribute="state"
                        name="state"
                        bluePrint={{ label: "Select State", displayfield: "name", entityAPI: "states" }}
                        value={stateValue}
                        disabled={false}
                        className="mp-search-dropdown"
                        variant="outlined"
                        onChange={setStateValue}
                    />
                    <ParentDropDown
                        attribute="category"
                        name="category"
                        bluePrint={{ label: "Select Category", displayfield: "name", entityAPI: "categories" }}
                        value={categoryValue}
                        disabled={false}
                        className="mp-search-dropdown ml-10"
                        variant="outlined"
                        onChange={setCategoryValue}
                    />

                    <button className='btn btn-secondary mp-search-button ml-10' onClick={handleSearchClick}>Search</button>
                </div>
            </div>
            <div className='height100 padder pt-1 booking-container'>
                {
                    bookings && bookings.map((booking, index) => <BookingCard key={index} data={booking} />)
                }
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return { ...state.authentication, ...state };
}

export default connect(mapStateToProps)(MarketPlace);