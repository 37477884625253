import React, { Fragment, useEffect, useState } from 'react';
import './style.css';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { apiService } from '../../../_services/api.service';
import { APILoader } from '../../../components/APILoader';
import config from '../../../_config';
import { GeoService } from '../../../_services/geo.service';
import getImageURL from '../../../_helpers/getImageURL';
import currencyDisplayHelper from '../../../_helpers/currencyDisplayHelper';
import { toast } from 'react-toastify';
import socketUpdater from '../../../_helpers/socketUpdater';

const Booking = ({ infosetting, user, loggedIn }) => {
    const { id } = useParams();
    const [booking, setBooking] = useState(null);
    const navigate = useNavigate();

    const [locationPermissionState, setLocationPermissionState] = useState(null);
    const [location, setlocation] = useState(null);

    const mapId = `map-${id}`;

    useEffect(() => {
        if (infosetting) {
            document.title = `Booking | ${infosetting.title}`;
            handlePermission();
        }
    }, [infosetting]);

    useEffect(() => {
        let tempSocket = null;
        if (id) {
            getBooking();

            tempSocket = socketUpdater('booking', (action, data) => {
                if (action === "update" && data && data._id === id) {
                    getBooking();
                }
            });

        }
    }, [id]);


    const getBooking = () => {
        apiService.getSingle("bookings", id)
            .then(setBooking)
            .catch(e => {
                navigate(-1);
            });
    }


    useEffect(() => {
        if (booking) {
            let mapElement = document.getElementById(mapId);
            new window.google.maps.Map(mapElement, { center: config.defaultLatLng });


            const focusOnPickupLocation = booking.status === "Confirmed" || booking.status === "Arrived" ? true : false
            const mapConfig = {
                // center: { lat: centerCoords.latitude, lng: centerCoords.longitude },
                center: focusOnPickupLocation ? {
                    lat: parseFloat(booking.pickupAddressLocation.coordinates[0]),
                    lng: parseFloat(booking.pickupAddressLocation.coordinates[1])
                } : GeoService.findCenter(
                    { latitude: booking.pickupAddressLocation.coordinates[0], longitude: booking.pickupAddressLocation.coordinates[1] },
                    { latitude: booking.destinationAddressLocation.coordinates[0], longitude: booking.destinationAddressLocation.coordinates[1] },
                ),
                zoom: focusOnPickupLocation ? 17 : GeoService.getZoomLevel(booking.distance, 2.2),
                streetViewControl: false, fullscreenControl: false, zoomControl: false,
                mapTypeControl: false,
                styles: [
                    {
                        featureType: "poi.business",
                        stylers: [{ visibility: "off" }],
                    },
                ],
            }


            const map = new window.google.maps.Map(
                mapElement,
                mapConfig
            );

            const directionsPath = new window.google.maps.Polyline({
                path: booking.directions.map(p => { return { lat: p.latitude, lng: p.longitude } }),
                strokeColor: "#326ce1",
                strokeOpacity: 1.0, strokeWeight: 3, geodesic: true,
            });

            directionsPath.setMap(map);

            const marker1 = new window.google.maps.Marker({
                position: {
                    lat: parseFloat(booking.pickupAddressLocation.coordinates[0]),
                    lng: parseFloat(booking.pickupAddressLocation.coordinates[1])
                },
                title: 'Pickup',
            });
            marker1.setMap(map);

            const marker2 = new window.google.maps.Marker({
                position: {
                    lat: parseFloat(booking.destinationAddressLocation.coordinates[0]),
                    lng: parseFloat(booking.destinationAddressLocation.coordinates[1])
                },
                title: 'Destination',
            });
            marker2.setMap(map);
        }
    }, [booking]);

    const handleAssignRider = async () => {
        try {
            let bookingData = JSON.parse(JSON.stringify(booking));
            bookingData.status = "Confirmed";
            bookingData.rider = user._id;
            await apiService.update("bookings", bookingData, bookingData._id);
            toast.success("This booking has been successfully assigned to you. Please proceed to the provided location!");
        } catch (err) {
            toast.error("An error occurred while assigning the rider!");
        }
    }

    const handlePermission = () => {
        if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({ name: 'geolocation' })
                .then(function (result) {
                    if (result.state === "granted") {
                        allowLocationService();
                    }
                })
        } else if (navigator.geolocation) {
            allowLocationService();
        }
    }

    const allowLocationService = () => {
        return new Promise((resolve, reject) => {
            var options = {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 1000
            };
            navigator.geolocation.getCurrentPosition(({ coords }) => {
                resolve(coords);
            }, (err) => {
                console.log({ err });

                resolve({ latitude: 0, longitude: 0 });
            }, options);
        });
    }

    const handleArrivedAtLocation = async () => {
        try {
            let coords = await allowLocationService();

            const distance = GeoService.manualDistance(coords.latitude, coords.longitude, booking.pickupAddressLocation.coordinates[0], booking.pickupAddressLocation.coordinates[1])
            // if (distance < 8) {
            let bookingData = JSON.parse(JSON.stringify(booking));
            bookingData.status = "Picked Up";
            await apiService.update("bookings", bookingData, bookingData._id);
            toast.success("Thank you for picking up the delivery. Please take it to the destination.");
            // } else {
            //     toast.error("Please arrive at the pickup location, then proceed to the destination.");
            // }
        } catch (error) {
            toast.error("Please enable location services to ensure all features function properly.");
        }
    }

    const handleDelivered = async () => {
        try {
            let coords = await allowLocationService();

            const distance = GeoService.manualDistance(coords.latitude, coords.longitude, booking.destinationAddressLocation.coordinates[0], booking.destinationAddressLocation.coordinates[1])
            // if (distance < 8) {
            let bookingData = JSON.parse(JSON.stringify(booking));
            bookingData.status = "Delivered";
            await apiService.update("bookings", bookingData, bookingData._id);
            toast.success("Delivery successful! Your items have arrived at the destination.");
            // } else {
            //     toast.error("Please arrive at the destination location, then proceed to the delivery.");
            // }
        } catch (error) {
            toast.error("Please enable location services to ensure all features function properly.");
        }
    }

    return (
        <div className='section'>
            {booking ? <div className='booking-main-container padder'>
                <div className='booking-left-area'>
                    <div className='booking-page-map' id={mapId}></div>
                    <div className='booking-map-inner'>
                        <div className='booking-distance center'>
                            {/* {data.categoryObj.name} */}
                            Distance: {booking.distance} km
                        </div>
                    </div>

                    {/* <div className='row booking-user-container2 mt-20'>
                        <div className='booking-user-image image2' style={{ backgroundImage: `url(${getImageURL(booking.userObj.profileImage)})` }}>
                        </div>

                        <div className='booking-user-data'>
                            <div className='booking-username ml-0'>
                                {booking.userObj.name}
                            </div>
                            <div className='booking-datatime'>
                                {new Date(booking.dateTime).toLocaleDateString()}
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className='booking-right-area'>
                    <div className='row space-between center'>
                        <h3 className='mb-30'>Booking Details</h3>
                        <div className='booking-status-container center'>
                            {booking.status}
                        </div>
                    </div>
                    <hr className='hr2' />

                    <p>Pickup</p>
                    <div className='booking-direction'>
                        <i className='fa fa-circle secondary-text'></i>
                        <span className='booking-direction-address'>{booking.pickupAddress}</span>
                    </div>
                    <p>Destination</p>
                    <div className='booking-direction'>
                        <i className='fa fa-circle secondary-text-2'></i>
                        <span className='booking-direction-address'>{booking.pickupAddress}</span>
                    </div>
                    <p>Type</p>
                    <div className='booking-p-category'>
                        <div className='booking-p-category-left center'>
                            <img src={getImageURL(booking.categoryObj.image)} className='booking-p-category-image' />
                            <span className='booking-p-category-title'>{booking.categoryObj.name}</span>
                        </div>
                        <div className='booking-p-category-right'>
                            <span className='booking-rate'>{currencyDisplayHelper(booking.price)}</span>
                        </div>
                    </div>
                    {
                        user.role == "Rider" ? <Fragment>
                            <p>Customer</p>
                            <div className='row booking-user-container2'>
                                <div className='booking-user-image image2' style={{ backgroundImage: `url(${getImageURL(booking.userObj.profileImage)})` }}>
                                </div>

                                <div className='booking-user-data'>
                                    <div className='booking-username ml-0'>
                                        {booking.userObj.name}
                                    </div>
                                    {booking.status !== "Requested" ?
                                        <a className='booking-datatime secondary-text' href={`tel:${booking.userObj.phoneNumber}`}>
                                            {booking.userObj.phoneNumber}
                                        </a> :
                                        <div className='booking-datatime'>
                                            {new Date(booking.dateTime).toLocaleDateString()}
                                        </div>}
                                </div>
                            </div>
                        </Fragment> :
                            booking.riderObj.name !== "N/A" ?
                                <Fragment>
                                    <p>Rider</p>
                                    <div className='row booking-user-container2'>
                                        <div className='booking-user-image image2' style={{ backgroundImage: `url(${getImageURL(booking.userObj.profileImage)})` }}>
                                        </div>

                                        <div className='booking-user-data'>
                                            <div className='booking-username ml-0'>
                                                {booking.riderObj.name}
                                            </div>
                                            <a className='booking-datatime secondary-text' href={`tel:${booking.riderObj.phoneNumber}`}>
                                                {booking.riderObj.phoneNumber}
                                            </a>
                                        </div>
                                    </div>

                                </Fragment> : null}

                    {
                        user.role == "Rider" ? <Fragment>
                            {booking.status === "Requested" && <Fragment>
                                <p className='booking-p-terms'>By clicking Continue, you agree to provide services in accordance with our terms and conditions.</p>

                                <button className='btn btn-block mt-5' onClick={handleAssignRider}>
                                    Continue
                                </button>
                            </Fragment>}

                            {booking.status === "Confirmed" && <Fragment>
                                <p className='booking-p-terms'>Always ensure your vehicle is ready, follow the route provided, and prioritize safety at all times.</p>

                                <button className='btn btn-block mt-5' onClick={handleArrivedAtLocation}>
                                    Collected the delivery
                                </button>
                            </Fragment>}

                            {booking.status === "Picked Up" && <Fragment>
                                <p className='booking-p-terms'>Drive safely and follow the fastest route to ensure timely delivery!</p>

                                <button className='btn btn-block mt-5' onClick={handleDelivered}>
                                    Delivered
                                </button>
                            </Fragment>}
                        </Fragment> : null
                    }


                </div>
            </div> :
                <div className='height80 center'>
                    <APILoader />
                </div>

            }
        </div>
    );
};


function mapStateToProps(state) {
    return { ...state.authentication, ...state };
}

export default connect(mapStateToProps)(Booking);