import React, { Fragment, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authenticationService } from '../../../_services/authentication.service';

const EmailVerification = () => {
    let params = useParams();
    const [searchParams] = useSearchParams();
    const [sending, setSending] = useState(false);
    const onSendEmail = () => {
        if (!sending) {
            setSending(true);
            authenticationService.sendVerificationEmail(params.email)
                .then(g => {
                    toast.success(g.message);
                    setSending(false);
                })
                .catch(err => {
                    toast.error(err.response.data.message);
                    setSending(false);
                })
        }
    }
    return (
        <div className='height100'>
            <div className='column center pd-top'>
                <h2 class="brand-text font-size-18 mt-4 ">{params.type === "register-success" ? "Almost Done!" : "Error"}</h2>
                <div className="column center">
                    {params.type !== "register-success" && <h5>Your Email is not verified yet!</h5>}
                    <span>We've emailed a verification link to:</span>
                    <p className="secondary-text">{params.email}</p>

                    <span className="light-gray">Please check your spam folder if you can't find the email in your inbox.</span>

                    <span className="light-gray mt-5">Click the link in email to finish creating your account.</span>
                    <br />
                    <span className="primary-text">Didn't receive a link?</span>
                    <br />
                    <button className="btn btn-primary" onClick={onSendEmail} disabled={sending}>{sending ? "Sending.." : "Send Email"}</button>
                    <p className='mt-4 form-navigation-text'>Confirmed? <Link to={`/auth/login${searchParams.get("continue") ? '?continue=' + searchParams.get("continue") : ''}`} className="primary-text">Back to Login</Link></p>
                </div>
            </div>

        </div>
    );
};

export default EmailVerification;