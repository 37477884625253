import React, { useEffect, useState } from 'react';
import './style.css';
import { useNavigate, useParams } from 'react-router-dom';
import Field from '../../../_common/field';
import AddressLocationFieldJS from '../../../components/AddressLocationField/AddressLocationFieldJS';
import AddressLocationField from '../../../components/AddressLocationField/AddressLocationField';
import { connect } from 'react-redux';
import { GeoService } from '../../../_services/geo.service';
import { apiService } from '../../../_services/api.service';
import getImageURL from '../../../_helpers/getImageURL';
import currencyDisplayHelper from '../../../_helpers/currencyDisplayHelper';
import { APILoader } from '../../../components/APILoader';
import { toast } from 'react-toastify';

const RideCategory = ({ infosetting, match, dispatch, history, user }) => {
    const { destination_coords, destination_address, pickup_coords, pickup_address } = useParams();
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [distance, setDistance] = useState(0);
    const [categories, setCategories] = useState([]);

    const [bookingPlaced, setBookingPlaced] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        calcualateDistance();
    }, [destination_coords, pickup_coords]);

    const getCategories = async () => {
        try {
            let categoryList = await apiService.getAll("categories");

            let distanceVal = calcualateDistance();
            if (distanceVal > 0 && categoryList.length > 0) {
                for (let i = 0; i < categoryList.length; i++) {
                    let category = categoryList[i];
                    category["rate"] = category.ratePerKM * distanceVal;
                }
                setCategories(categoryList);
                setLoading(false);
            } else {
                toast.error("Pickup and destination locations must be different!");
                navigate(-1);
            }
        } catch (error) {

        }

    }

    const calcualateDistance = () => {
        const pickupCoords = GeoService.extractCoordsfromURL(pickup_coords);
        const destinationCoords = GeoService.extractCoordsfromURL(destination_coords);
        const distanceVal = GeoService.manualDistance(pickupCoords.lat, pickupCoords.lng,
            destinationCoords.lat, destinationCoords.lng);
        setDistance(distanceVal);
        return distanceVal;
    }


    const handleConfirmClick = async () => {
        setLoading(true);

        const pickupCoords = GeoService.extractCoordsfromURL(pickup_coords);
        const destinationCoords = GeoService.extractCoordsfromURL(destination_coords);

        const selectedCategoryObj = categories[selectedCategory];
        try {

            const bookingData = {
                user: user._id,
                category: selectedCategoryObj._id,
                state: user.state,
                pickupAddress: pickup_address,
                pickupAddressLocation: {
                    type: "Point",
                    coordinates: [pickupCoords.lat, pickupCoords.lng]
                },
                destinationAddress: destination_address,
                destinationAddressLocation: {
                    type: "Point",
                    coordinates: [destinationCoords.lat, destinationCoords.lng]
                },
                distance,
                price: parseFloat(parseFloat(selectedCategoryObj.rate).toFixed(2)),
                status: "Requested"
            }

            await apiService.add("bookings", bookingData);
            setLoading(false);
            setBookingPlaced(true);
        } catch (error) {
            console.log({ error });
            setLoading(false);
            toast.error("An error occurred while placing the booking!")
        }

    }
    return loading ?
        <div className='section'>
            <div className='banner-bg center'>
                <span className='title-white'>Almost there!</span>
            </div>
            <div className='padder center mt-50'>
                <APILoader />
            </div>

        </div>
        : bookingPlaced ?
            (
                <div className='section'>
                    <div className='banner-bg center'>
                        <span className='title-white'>Success!</span>
                    </div>
                    <div className='padder center column booking-success' >
                        <i className='fa fa-circle-check success-icon'></i>
                        <h3>Ride Booking Placed</h3>
                        <p className='text-center'>Your ride request is in the marketplace. A rider will accept soon,<br /> and you'll receive a notification.</p>
                        <div className="submission-container center mt-50">
                            <button class="btn btn-primary btn-block" onClick={() => setBookingPlaced(true)}>View Market Place</button>
                        </div>
                    </div>
                </div>
            )
            : (
                <div className='section'>
                    <div className='banner-bg center'>
                        <span className='title-white'>Select Category</span>
                    </div>
                    <div className='padder center column' >
                        <div className='s-categories'>
                            {categories.map((category, key) => <div className={`s-category ${key === selectedCategory ? 's-category-selected' : ''}`} onClick={() => setSelectedCategory(key)}>
                                <div className='s-category-inner'>
                                    <img src={getImageURL(category.image)} />
                                    <h3>{category.name}</h3>
                                    <p>{category.description}</p>
                                </div>
                                <div className='c-category-bottom'>
                                    <span>
                                        Distance {distance} km
                                    </span>
                                    <span>
                                        Price {currencyDisplayHelper(category.rate)}
                                    </span>
                                </div>
                            </div>)}
                        </div>

                        <div className="submission-container category-button center mt-50">
                            <button class="btn btn-primary btn-block" onClick={handleConfirmClick}>Confirm</button>
                        </div>

                    </div>
                </div>
            );
};


function mapStateToProps(state) {
    return { ...state.authentication, ...state };
}

export default connect(mapStateToProps)(RideCategory);