import { authenticationConstants } from "../_constants/authentication.constants";
import { authenticationService } from "../_services/authentication.service";
import { alertActions } from "./alert.actions";
// import { navigate } from "../_helpers";

export const authenticationActions = {
    login: function (data, navigate, setLoading, callbackError, redirect) {
        setLoading(true);
        return dispatch => {
            dispatch(alertActions.clear());
            dispatch(request({ email: data.email }));

            authenticationService.login(data).then(token => {
                setLoading(false);
                dispatch(success(token));
                navigate(redirect ? redirect : "/account/profile");
            }).catch(e => {
                let error = e.response ? e.response.data.message : e.message;
                setLoading(false);
                callbackError && callbackError(error);
                if (error === "Your Email is not verified yet!") {
                    dispatch(alertActions.clear());
                    navigate(`/email/unverified-email/${data.email}`);
                    return;
                }

                dispatch(failure(error));
                dispatch(alertActions.error(error === "Network Error" ? "Internet Problem" : error));
            }
            );
        };
        function request(email) {
            return { type: authenticationConstants.LOGIN_REQUEST, email };
        }
        function success(token) {
            return { type: authenticationConstants.LOGIN_SUCCESS, token };
        }
        function failure(error) {
            return { type: authenticationConstants.LOGIN_FAILURE, error };
        }
    },

    getCurrentUser: function (navigate) {
        return dispatch => {
            dispatch(request());
            authenticationService.getCurrentUser().then(
                authentication => {
                    localStorage.setItem('flag', btoa(JSON.stringify(authentication)));
                    dispatch(success(authentication));
                },
                error => {
                    if (error.tokenNotFound) {
                        // console.log(error);
                    } else {
                        dispatch(failure(error));
                        navigate && navigate("/auth/login");
                    }

                    // dispatch(alertActions.error(error));
                }
            );
        };

        function request() {
            return { type: authenticationConstants.GET_CURRENT_REQUEST };
        }
        function success(authentication) {
            return { type: authenticationConstants.GET_CURRENT_SUCCESS, user: authentication.user };
        }
        function failure(error) {
            return { type: authenticationConstants.GET_CURRENT_FAILURE, error };
        }
    },
    socialLogin: function (data, navigate, setLoading, callbackError) {
        setLoading(true);
        return dispatch => {
            dispatch(alertActions.clear());
            dispatch(request({ email: data.email }));

            authenticationService.socialLogin(data).then(token => {
                setLoading(false);
                dispatch(success(token));
                navigate("/account/profile");
            }).catch(e => {
                let error = e.response ? e.response.data.message : e.message;
                setLoading(false);
                callbackError && callbackError(error);
                if (error === "Your Email is not verified yet!") {
                    dispatch(alertActions.clear());
                    navigate(`/email/unverified-email/${data.email}`);
                    return;
                }
                else if (error === "require-2fa-auth") {
                    dispatch(alertActions.clear());
                    // navigate(`/verify-2fa/${btoa(password)}/${email}`);
                    return;
                }
                dispatch(failure(error));
                dispatch(alertActions.error(error === "Network Error" ? "Internet Problem" : error));
            }
            );
        };
        function request(email) {
            return { type: authenticationConstants.LOGIN_REQUEST, email };
        }
        function success(token) {
            return { type: authenticationConstants.LOGIN_SUCCESS, token };
        }
        function failure(error) {
            return { type: authenticationConstants.LOGIN_FAILURE, error };
        }
    },
    getCurrentUserLocal: () => {
        let flag = localStorage.getItem("flag");
        if (!flag) {
            return { user: null };
        }
        return JSON.parse(atob(flag));
    },
    logout: function (navigate) {
        authenticationService.logout();
        navigate("/auth/login");
        return { type: authenticationConstants.LOGOUT };
    }
};
