import React, { useEffect, useState } from 'react';
import './style.css';
import { connect } from 'react-redux';
import { apiService } from '../../../_services/api.service';
import BookingCard from '../../../components/BookingCard';

const UserBookings = ({ infosetting, user, loggedIn }) => {
    const [bookings, setBookings] = useState(null);

    useEffect(() => {
        if (infosetting) {
            document.title = `My Bookings | ${infosetting.title}`;
        }

    }, [infosetting]);

    useEffect(() => {
        fetchBookings();
        console.log({ user });

    }, []);



    const fetchBookings = () => {
        apiService.filter("bookings", { query: user.role === "Rider" ? { rider: user._id } : { user: user._id } })
            .then(setBookings);
    }
    return (
        <div>
            <h3>Bookings</h3>
            <p className='mt-0'>Your Bookings at a Glance</p>
            <hr className='hr2' />

            <div className='height100 pt-1 booking-container booking-container-user'>
                {
                    bookings && bookings.map((booking, index) => <BookingCard key={index} data={booking} showStatus />)
                }
            </div>
        </div>
    );
};


function mapStateToProps(state) {
    return { ...state.authentication, ...state };
}

export default connect(mapStateToProps)(UserBookings);