import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

export const AuthenticatedRoute = ({ children }) => {
  const params = useParams();
  const navigate = useNavigate()
  const [ready, setReady] = useState(false);
  const defaultRoute = `/auth/signup`;
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      if (params && Object.keys(params).length > 0) {
        let returnURL = window.location.href;
        returnURL = returnURL.split("#")[1];
        navigate(`${defaultRoute}?continue=${returnURL}`)
      } else {
        navigate(defaultRoute);
      }
    } else {
      setReady(true);
    }
  }, [params]);

  if (!ready)
    return null;

  return children;
};