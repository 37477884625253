import axios from "axios";
import config from "../_config";
var polyline = require('@mapbox/polyline');


export const GeoService = {
    manualDistance: function (lat1, lon1, lat2, lon2) {
        var p = 0.017453292519943295;    // Math.PI / 180
        var c = Math.cos;
        var a = 0.5 - c((lat2 - lat1) * p) / 2 +
            c(lat1 * p) * c(lat2 * p) *
            (1 - c((lon2 - lon1) * p)) / 2;
        var d = 12742 * Math.asin(Math.sqrt(a));
        let ud = d + (d / 2);
        return parseFloat(parseFloat(ud + "").toFixed(2));
    },
    extractCoordsfromURL: (coords) => {
        let coordsArr = coords.split(',');
        let lat = parseFloat(coordsArr[0]);
        let lng = parseFloat(coordsArr[1]);
        return { lat, lng };
    },
    centerCoordinates: (arr, distance) => {
        var x = arr.map((x) => x.latitude);
        var y = arr.map((x) => x.longitude);
        var cx = (Math.min(...x) + Math.max(...x)) / 2;
        var cy = (Math.min(...y) + Math.max(...y)) / 2;
        return {
            latitude: cx - 0.00030, longitude: cy,
            latitudeDelta: distance / 60,
            longitudeDelta: distance / 60
        };
    },

    getDirections: (startLoc, destinationLoc, googleMapsAPIKey = config.googleMapAPIKey) => {
        return new Promise((resolve, reject) => {
            debugger;
            axios.get('https://maps.googleapis.com/maps/api/directions/json?origin=' + startLoc.lat + ',' + startLoc.lng + '&destination=' + destinationLoc.lat + ',' + destinationLoc.lng + '&key=' + googleMapsAPIKey)
                .then(respJson => respJson.data)
                .then(respJson => {
                    if (respJson.routes) {
                        let points = polyline.decode(respJson.routes[0].overview_polyline.points);
                        let coords = points.map((point, index) => {
                            return {
                                latitude: point[0],
                                longitude: point[1]
                            }
                        })
                        resolve(coords)
                    } else {
                        reject("Unable to get direction points");
                    }
                }).catch(error => {
                    console.log(error);
                    reject(error)
                })

        });
    },

    findCenter: function (coord1, coord2) {
        const lat1 = coord1.latitude;
        const lng1 = coord1.longitude;
        const lat2 = coord2.latitude;
        const lng2 = coord2.longitude;

        // Calculate the midpoint
        const centerLat = (lat1 + lat2) / 2;
        const centerLng = (lng1 + lng2) / 2;

        return { lat: centerLat, lng: centerLng };
    },

    getZoomLevel: function (distance, k = 2.6, c = 20) {
        const C = 20; // constant

        // Input validation
        if (typeof distance !== 'number' || distance < 0) {
            throw new Error('Invalid distance: Distance must be a non-negative number.');
        }

        // Calculate zoom level using a mathematical formula
        const zoomLevel = Math.max(
            0,
            Math.min(14, distance > 20 ? 10 : (C - k * Math.log2(Math.max(distance, 1))))
        );

        return zoomLevel;
    }
}